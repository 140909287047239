function autocompleteAjax(wrapper) {

    const input = wrapper.querySelector('input[type=text]');
    const target = wrapper.querySelector('input[type=hidden]');
    const ul = document.createElement('ul');
    ul.classList.add('list-group', 'px-1', 'invisible')

    wrapper.appendChild(ul);

    input.addEventListener('focus', () => {
        input.select();
    })

    input.addEventListener("keypress", function (event) {
        if (ul.childElementCount === 1 && event.key === "Enter") {
            event.preventDefault();
            ul.querySelector('li:first-child').dispatchEvent(new Event('click'));
        }
    });

    input.addEventListener('keyup', (event) => {

        if (event.key === "Enter") {
            return;
        }

        if (event.target.value === '') {
            ul.innerHTML = null;
            ul.classList.replace('visible', 'invisible');
        }

        target.value = null;
    })

    input.addEventListener('keyup', debounce((event) => {
        if (event.target.value === '') {
            return
        }

        const url = new URL(wrapper.dataset.url, document.location);
        url.searchParams.set('q', event.target.value)
        fetch(url.toString())
            .then((resp) => resp.json())
            .then(function (data) {
                ul.innerHTML = null;
                const values = event.target.value.split(' ');
                for (const item of data) {
                    const li = document.createElement('li');
                    li.classList.add('list-group-item', 'list-group-item-action', 'p-1', 'border-top-0')
                    let innerHtml = item.option;
                    for (let i = 0; i < values.length; i++) {
                        innerHtml = innerHtml.replace(new RegExp('(' + values[i] + ')', 'i'), '<strong>$1</strong>');
                    }
                    li.innerHTML = innerHtml;
                    li.setAttribute('data-value', item.value);
                    li.addEventListener('click', select.bind(event, item, target, input, ul))
                    ul.appendChild(li);
                }

                ul.classList.replace('invisible', 'visible');
            });
    }, 300))
}

const select = (item, target, input, ul) => {
    target.value = item.value;
    input.value = item.label;
    ul.innerHTML = null;
    ul.classList.replace('visible', 'invisible');
    input.dispatchEvent(new CustomEvent('itemSelected', {detail: item}));
}

const debounce = (func, wait, immediate) => {
    let timeout

    return function () {
        const context = this, args = arguments
        const later = function () {
            timeout = null
            if (!immediate) func.apply(context, args)
        }

        const callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
    }
}

export default function () {
    const elements = document.getElementsByClassName('autocomplete');

    for (let element of elements) {
        autocompleteAjax(element);
    }
}
