export default function ajaxForm(formEl, callback = null, idTarget = null) {
    formEl.addEventListener('submit', (event) => {
        event.preventDefault();
        formEl.querySelector('button[type=submit]').disabled = true;
        fetch(formEl.action, {
            method: 'POST',
            body: new URLSearchParams(new FormData(formEl)),
        })
            .then(response => {
                const contentType = response.headers.get('content-type');
                if (contentType && contentType.indexOf('application/json') !== -1) {
                    return response.json()
                        .then(data => {
                            if (true === data.hasOwnProperty('redirection')) {
                                if (data.redirection === true) {
                                    window.location.replace(data.url)
                                }
                            }
                        });
                } else {
                    return response.text()
                        .then(text => {
                            const htmlResponse = (new DOMParser()).parseFromString(text, 'text/html');
                            const newForm = htmlResponse.documentElement.querySelector(idTarget ? '#' + idTarget + ' form' : 'form');
                            formEl.replaceWith(newForm);
                            if (null !== callback) {
                                callback(newForm)
                            }
                            ajaxForm(newForm, callback, idTarget);
                        });
                }
            })
            .catch(error => console.error("Error : " + error));
    })
}
