export default function tableChecker(tableEl) {
    const checkerAll = tableEl.querySelector('input[type=checkbox].checker-all');
    const checkerItems = tableEl.querySelectorAll('input[type=checkbox].checker-item');

    checkerAll.addEventListener('change', () => {
        if (checkerAll.checked === true) {
            checkerItems.forEach(item => {
                item.checked = true;
            })
        } else {
            checkerItems.forEach(item => {
                item.checked = false;
            })
        }
    })

    checkerItems.forEach(item => {
        item.addEventListener('change', (event) => {
            let allSame = true;

            checkerItems.forEach(checkbox => {
                if (checkbox.checked !== item.checked) {
                    allSame = false;
                }
            })

            checkerAll.checked = allSame === true && item.checked === true;
        })
    })
}