function selectAutocomplete(rootEl) {
    const realSelect = rootEl.querySelector('select');
    const fakeSelect = rootEl.querySelector('input.select-autocomplete-select');
    const dropdown = rootEl.querySelector('.dropdown');
    const search = dropdown.querySelector('input.select-autocomplete-search');
    const list = dropdown.querySelector('.results ul');

    fakeSelect.value = realSelect.options[realSelect.selectedIndex].text;

    dropdown.addEventListener('shown.bs.dropdown', event => {
        search.select();
        search.dispatchEvent(new Event('input'));
    });

    search.addEventListener('input', event => {
        list.innerHTML = '';
        let added = 0;

        Array.from(realSelect.options).some((option) => {

            if (option.text.toUpperCase().indexOf(search.value.toUpperCase()) > -1) {
                let li = document.createElement('li');
                li.classList.add('dropdown-item', 'cursor-pointer');
                li.textContent = option.text;

                li.addEventListener('click', () => {
                    realSelect.value = option.value;
                    fakeSelect.value = realSelect.options[realSelect.selectedIndex].text;
                    search.value = '';
                })

                list.appendChild(li);
                added++;
            }

            return added === 25;
        });
    });
}

export default function () {
    const elements = document.getElementsByClassName('select-autocomplete');

    for (let element of elements) {
        selectAutocomplete(element);
    }
}
