import onScan from 'onscan.js';
import ajaxForm from "./ajax-form";

export default function cashRegister() {
    const cashRegister = document.getElementById('cash-register');

    if (null === cashRegister) {
        return;
    }

    const queryParams = new URLSearchParams(window.location.search);

    const selectProduct = (tr) => {
        if (tr) {
            document.querySelectorAll('#ticket-pane tr.product').forEach(tr => {
                tr.classList.remove('active');
            });
            tr.classList.add('active');
            syncQueryParameters('selected', tr.dataset.product);
        }
    };

    const go = (to) => {
        const url = new URL(to, window.location.origin);

        new URLSearchParams(window.location.search).forEach((value, key) => {
            url.searchParams.set(key, value);
        })

        location.replace(url);
    }

    const scan = () => {
        onScan.attachTo(document);
        document.addEventListener('scan', function (scan) {
            if (scan.detail.scanCode && false === allCharactersSame(scan.detail.scanCode)) {
                go(cashRegister.dataset.urlScan + '/' + scan.detail.scanCode)
            }
        });
    }

    numpad();

    document.querySelectorAll('#search-pane tr.product').forEach(tr => {
        tr.addEventListener('click', () => {
            go(tr.dataset.url)
        })
    });

    document.querySelectorAll('input.input-sync').forEach(input => {
        input.addEventListener('change', (event) => {
            syncQueryParameters(input.dataset.key, input.value);
        })

        input.addEventListener('keyup', (event) => {
            if (event.key === 'Enter') {
                location.reload();
            } else {
                syncQueryParameters(input.dataset.key, input.value);
            }
        })
    });

    const ticketPane = document.getElementById('ticket-pane');

    //Select product + update line
    if (ticketPane.dataset.status !== 'validated') {
        let product;
        if (queryParams.has('selected') && queryParams.get('selected') !== '') {
            product = document.querySelector('#ticket-pane table.products .product-' + queryParams.get('selected'));
        } else {
            product = document.querySelector('#ticket-pane table.products tr:last-child');
        }

        if (product) {
            selectProduct(product);
            product.scrollIntoView();
        }

        document.querySelectorAll('#ticket-pane tr.product').forEach(tr => {
            tr.addEventListener('click', () => {
                selectProduct(tr);
                product = tr;
            })
        });

        const screen = document.getElementById('numpad-screen')
        document.querySelectorAll('#numpad-keyboard button.update-line').forEach((button) => {
            button.addEventListener('click', () => {
                if (product && product.dataset.updateLineUrl && screen.value !== '') {
                    const url = new URL(product.dataset.updateLineUrl, window.location.origin);

                    new URLSearchParams(window.location.search).forEach((value, key) => {
                        url.searchParams.set(key, value);
                    })

                    if (button.dataset.type === 'discount') {
                        url.searchParams.set('line_discount', Math.abs(screen.value))
                    } else if (button.dataset.type === 'price') {
                        url.searchParams.set('line_price', Math.abs(screen.value))
                    } else if (button.dataset.type === 'quantity') {
                        url.searchParams.set('line_quantity', screen.value)
                    }

                    window.location.assign(url);
                }
            })
        })

        const thirdPartyModalEl = document.getElementById('modal-third-party');

        if (thirdPartyModalEl) {
            const thirdPartyModal = bootstrap.Modal.getOrCreateInstance(thirdPartyModalEl);

            document.getElementById('pad-client').addEventListener('click', () => {
                thirdPartyModal.show();
            })

        }

        document.getElementById('third-party-default').addEventListener('click', (event) => {
            document.getElementById('third_party_thirdParty').value = event.currentTarget.dataset.value;
            document.querySelector('form[name=third_party]').submit();
        })
    }

    // Pending
    document.getElementById('pending-tickets').addEventListener('change', (event) => {
        window.location.assign(event.currentTarget.value);
    });

    // Payment
    const paymentButton = document.getElementById('pad-pay');
    const paymentModal = document.getElementById('modal-payment-ticket');

    if (paymentButton && paymentModal) {
        const modal = bootstrap.Modal.getOrCreateInstance(paymentModal);

        const form = paymentModal.querySelector('form');

        paymentButton.addEventListener('click', () => {
            modal.show();
        })

        paymentModal.addEventListener('hide.bs.modal', (event) => {
            event.preventDefault()
            location.reload()
        })

        const cashback = (print = true) => {
            const selected = paymentModal.querySelector('#settlement_type input[type=radio]:checked');

            if (print && paymentModal.querySelector('.modal-body').dataset.paid == 'paid') {
                const ticketData = paymentModal.querySelector('.modal-body').dataset.thermal;
                const printerApi = paymentModal.querySelector('.modal-body').dataset.printer;

                fetch(ticketData)
                    .then(response => response.json())
                    .then(json => {
                        fetch(printerApi, {
                            method: 'POST',
                            referrerPolicy: 'unsafe-url',
                            headers: {'Content-Type': 'application/json'},
                            body: JSON.stringify(json),
                        }).catch(error => console.error("Error : " + error));
                    })
                    .catch(error => console.error("Error : " + error));
            }

            if (selected) {
                if (selected.value === 'cash') {
                    paymentModal.querySelector('tr.cash').classList.remove('d-none');
                } else {
                    paymentModal.querySelector('tr.cash').classList.add('d-none');
                }
            } else {
                paymentModal.querySelector('tr.cash').classList.add('d-none');
            }

            paymentModal.querySelectorAll('#settlement_type input[type=radio]').forEach(input => {
                input.addEventListener('change', () => {
                    if (input.value === 'cash') {
                        paymentModal.querySelector('tr.cash').classList.remove('d-none');
                    } else {
                        paymentModal.querySelector('tr.cash').classList.add('d-none');
                    }
                })
            })

            if (paymentModal.querySelector('#settlement_amount')) {
                function computeGiveback() {
                    const settlementAmount = paymentModal.querySelector('#settlement_amount').value;
                    const cashIn = paymentModal.querySelector('.cash-in input[type=number]').value;
                    const giveback = settlementAmount - cashIn;

                    if (false === isNaN(giveback) && -giveback >= 0) {
                        paymentModal.querySelector('.giveback span.amount').innerHTML = -giveback.toFixed(2);

                    } else {
                        paymentModal.querySelector('.giveback span.amount').innerHTML = '0.00';
                    }
                }

                paymentModal.querySelector('.cash-in input[type=number]').addEventListener('keyup', computeGiveback)
                paymentModal.querySelector('#settlement_amount').addEventListener('keyup', computeGiveback)
            }
        }

        cashback(false);
        ajaxForm(form, cashback);
    }

    const reprint = document.getElementById('reprint-ticket');

    if (reprint){
        reprint.addEventListener('click', () => {
            const ticketData = reprint.dataset.thermal;
            const printerApi = reprint.dataset.printer;

            fetch(ticketData)
                .then(response => response.json())
                .then(json => {
                    fetch(printerApi, {
                        method: 'POST',
                        referrerPolicy: 'unsafe-url',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify(json),
                    }).catch(error => console.error("Error : " + error));
                })
                .catch(error => console.error("Error : " + error));
        })
    }

    scan();
}

function numpad() {
    const screen = document.getElementById('numpad-screen');
    const sign = document.getElementById('numpad-sign');
    const numbers = document.querySelectorAll('#numpad-keyboard button.number');
    const regex = new RegExp('^(-?(\\d+(\\.\\d{0,2})?)?)?$'); //minus
    const numberKeys = ['-', '.', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    numbers.forEach((number) => {
        number.addEventListener('click', event => {
            const number = event.currentTarget.dataset.value;
            const newValue = String(screen.value) + '' + number;

            if (regex.test(newValue)) {
                screen.value = newValue;
                screen.dispatchEvent(new Event('change'));
            }
        })
    })

    sign.addEventListener('click', event => {
        screen.value = -screen.value;
    })

    document.getElementById('numpad-cancel').addEventListener('click', () => {
        screen.value = null;
        screen.dispatchEvent(new Event('change'));
    })

    document.addEventListener('keydown', event => {
        if (screen === document.activeElement) {
            if (false === numberKeys.includes(event.key) && false === ['Backspace', 'Delete'].includes(event.key)) {
                event.preventDefault();
            }

            let newValue = String(screen.value);
            if (numberKeys.includes(event.key)) {
                newValue = String(screen.value) + '' + event.key;
            } else if (['Backspace', 'Delete'].includes(event.key)) {
                newValue = screen.value.slice(0, -1);
            }

            if (false === regex.test(newValue)) {
                event.preventDefault();
            }
        }
    });
}

function syncQueryParameters(key, value) {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set(key, value);
    history.replaceState(null, null, "?" + queryParams.toString());
}

function allCharactersSame(str) {
    let n = str.length;
    for (let i = 1; i < n; i++) if (str[i] !== str[0]) return false;

    return true;
}

const SaleLine = function (discount = null, price = null, quantity = null,) {
    this.discount = discount ? parseFloat(discount) : null;
    this.price = price ? parseFloat(price) : null;
    this.quantity = quantity ? parseInt(quantity) : null;
};